import React from "react"
import Layout from "../components/layout"
import Article from "../components/article"
import GamemodeLogo from "../images/mtagm/gm-logo.png"


const Projects = () => {
    return (
        <Layout headerText="projects">
            <Article
                header="Multi Theft Auto - Gamemode"
                shortDescription="Simply start gamemode for servers. Project is under development."
                image={GamemodeLogo}
                linkTo="/projects/mtagm"
                linkToText="go to overview"
            >
            </Article>
        </Layout>
    )
}

export default Projects